import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  FilterDropdown,
  Select,
} from "@pankod/refine-antd";

import { Db } from "interfaces";
import { useAppContext } from '../../context';
import { useSelectCourses } from '../../hooks/useSelectCourses';
import { useSelectChapters } from '../../hooks/useSelectChapters';

export const StoryUserLogList: React.FC<IResourceComponentsProps> = () => {
  const { appId } = useAppContext();
  const { tableProps, sorter } = useTable<Db['StoryUserLog']>({
    permanentFilter: [
      {
        field: 'app_id',
        operator: 'eq',
        value: appId,
      }
    ],
    initialSorter: [
      {
        field: "created_at",
        order: "asc",
      },
    ],
    metaData: {
      select: "*, courses(title), chapters(title)",
    },
  });

  const { selectProps } = useSelectCourses(appId);
  const { selectProps: selectChapterProps } = useSelectChapters(appId);

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          key="id"
          dataIndex="id"
          title="ID"
          sorter
        />
        <Table.Column
          key="created_at"
          dataIndex="created_at"
          title="日時"
          sorter
        />
        <Table.Column
          key="user"
          dataIndex="user"
          title="ユーザー"
          sorter
          render={(value) => {
            return (
              <span>{value['nickname']}</span>
            )
          }}
        />
        <Table.Column
          key="course_id"
          dataIndex={["courses", "title"]}
          title="Course"
          defaultSortOrder={getDefaultSortOrder(
            "courses.title",
            sorter,
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Course"
                {...selectProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          key="chapter_id"
          dataIndex={["chapters", "title"]}
          title="Chapter"
          defaultSortOrder={getDefaultSortOrder(
            "chapters.title",
            sorter,
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Chapter"
                {...selectChapterProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          key="score"
          dataIndex="score"
          title="スコア"
          sorter
        />
        <Table.Column
          key="complete"
          dataIndex="complete"
          title="ステータス"
          sorter
          render={(value) => {
            return (
              <span>{value ? 'クリア' : '失敗' }</span>
            )
          }}
        />
      </Table>
    </List>
  );
};
