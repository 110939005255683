import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  Button,
} from '@pankod/refine-antd';

import { Db } from 'interfaces';
import { useAppContext } from '../../context';
import { LinkOutlined } from '@ant-design/icons';

export const CourseList: React.FC<IResourceComponentsProps> = () => {
  const { appId } = useAppContext();
  const { tableProps } = useTable<Db['Course']>({
    permanentFilter: [
      {
        field: 'app_id',
        operator: 'eq',
        value: appId,
      }
    ],
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  });

  return (
    <List>
      <Table { ...tableProps } rowKey="id">
        <Table.Column
          key="title"
          dataIndex="title"
          title="タイトル"
          sorter
        />
        <Table.Column
          key="permalink"
          dataIndex="permalink"
          title="パーマリンク"
          sorter
        />
        <Table.Column<Db['Course']>
          title="Actions"
          dataIndex="actions"
          render={ (_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={ record.id }
              />
              <Button href={ `https://hayaben.jp/genre/${ appId }/course/${ record.permalink }` }
                      target="_blank"
                      size="small"
                      icon={ <LinkOutlined/> }/>
            </Space>
          ) }
          width={ '5em' }
        />
      </Table>
    </List>
  );
};
