import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { supabaseClient } from '../utility';
import { User } from '@supabase/supabase-js';
import { AdminUserRole, Db } from '../interfaces';

type AppContextValue = {
  organizationId: string,
  role: AdminUserRole,
  appId: string,
  setAppId: (value: string) => void,
};

export const AppContext = createContext<AppContextValue>({
  organizationId: '',
  role: '',
  appId: '',
  setAppId: () => null,
});

export const AppProvider: FC<{ user: User | null, children: ReactNode }> = (props) => {
  const uid = props.user?.id;
  const [ appId, setAppId ] = useState('');
  const [ organizationId, setOrganizationId ] = useState('');
  const [ role, setRole ] = useState('');
  useEffect(() => {
    if (!uid) {
      return;
    }

    const fetch = async () => {
      try {
        const { data: adminUser } = await supabaseClient.from<Db['AdminUser']>('admin_users').select().eq('user_id', uid).single();
        if (!adminUser) {
          return null;
        }

        setOrganizationId(adminUser.organization_id ?? '');
        setRole(adminUser.role ?? '');
        const { data: app } = await supabaseClient.from<Db['App']>('apps').select().eq('organization_id', adminUser.organization_id).single();
        setAppId(app?.id ?? '');
      } catch (error) {
        console.error(error);
      }
    }
    void fetch();
  }, [ uid ]);

  return <AppContext.Provider value={ { appId, organizationId, role, setAppId } } { ...props } />;
}

export const useAppContext = () => {
  return useContext(AppContext);
}
