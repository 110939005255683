import {
  useTranslate,
  useLogout,
  useTitle,
  CanAccess,
  ITreeMenu,
  useIsExistAuthentication,
  useRouterContext,
  useMenu,
  useRefineContext,
} from "@pankod/refine-core";
import {
  AntdLayout,
  Grid,
  Icons,
  Menu,
  Title as DefaultTitle,
} from "@pankod/refine-antd";
import { CSSProperties, useState } from 'react';
import { useAppContext } from '../../context';
import { AppSelect } from '../select';

export type SiderRenderProps = {
  items: JSX.Element[];
  logout: React.ReactNode;
  dashboard: React.ReactNode;
};

export type RefineLayoutSiderProps = {
  render?: (props: SiderRenderProps) => React.ReactNode;
};

const { DashboardOutlined, LogoutOutlined, UnorderedListOutlined } = Icons;

export const CustomSider: React.FC<RefineLayoutSiderProps> = ({ render }) => {
  const {role, organizationId} = useAppContext();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isExistAuthentication = useIsExistAuthentication();
  const { Link } = useRouterContext();
  const { mutate: mutateLogout } = useLogout();
  const Title = useTitle();
  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const { hasDashboard } = useRefineContext();

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const RenderToTitle = Title ?? DefaultTitle;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;

      if (children.length > 0) {
        return (
          <CanAccess
            key={route}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            <Menu.SubMenu
              key={route}
              icon={icon ?? <UnorderedListOutlined />}
              title={label}
            >
              {renderTreeView(children, selectedKey)}
            </Menu.SubMenu>
          </CanAccess>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(
        parentName !== undefined && children.length === 0
      );
      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const logout = isExistAuthentication ? (
    <Menu.Item
      key="logout"
      onClick={() => mutateLogout()}
      icon={<LogoutOutlined />}
    >
      {translate("buttons.logout", "Logout")}
    </Menu.Item>
  ) : null;

  const dashboard = hasDashboard ? (
    <Menu.Item
      key="dashboard"
      style={{
        fontWeight: selectedKey === "/" ? "bold" : "normal",
      }}
      icon={<DashboardOutlined />}
    >
      <Link to="/">{translate("dashboard.title", "Dashboard")}</Link>
      {!collapsed && selectedKey === "/" && (
        <div className="ant-menu-tree-arrow" />
      )}
    </Menu.Item>
  ) : null;

  const appSelect = organizationId || role === 'SUPER_ADMIN' ? (
    <Menu.Item key={ '/' } icon={ <Icons.AppstoreAddOutlined/> }>
      <AppSelect/>
    </Menu.Item>
  ): null;

  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        items,
        logout,
      });
    }
    return (
      <>
        {dashboard}
        {items}
        {logout}
      </>
    );
  };

  const antLayoutSider: CSSProperties = {
    position: "relative",
  };
  const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <RenderToTitle collapsed={collapsed} />
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        { appSelect }
        {renderSider()}
      </Menu>
    </AntdLayout.Sider>
  );
};

// import React, { useState } from "react";
// import {
//   useTitle,
//   useMenu,
//   ITreeMenu,
//   CanAccess,
//   useRouterContext, useIsExistAuthentication, useLogout,
// } from '@pankod/refine-core';
// import {
//   AntdLayout,
//   Menu,
//   Grid,
//   Icons,
//   Title as DefaultTitle,
// } from "@pankod/refine-antd";
// import { antLayoutSider, antLayoutSiderMobile } from "./styles";
//
// import { AppSelect } from "../select";
//
// export const Sider: React.FC = () => {
//   const [collapsed, setCollapsed] = useState<boolean>(false);
//   const { Link } = useRouterContext();
//   const Title = useTitle() || DefaultTitle;
//   const { SubMenu } = Menu;
//   const { menuItems, selectedKey } = useMenu();
//   const breakpoint = Grid.useBreakpoint();
//
//   const isMobile =
//     typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;
//
//   const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
//     return tree.map((item: ITreeMenu) => {
//       const { icon, label, route, name, children, parentName } = item;
//
//       if (children.length > 0) {
//         return (
//           <SubMenu
//             key={name}
//             icon={icon ?? <Icons.UnorderedListOutlined />}
//             title={label}
//           >
//             {renderTreeView(children, selectedKey)}
//           </SubMenu>
//         );
//       }
//       const isSelected = route === selectedKey;
//       const isRoute = !(
//         parentName !== undefined && children.length === 0
//       );
//       return (
//         <CanAccess
//           key={route}
//           resource={name.toLowerCase()}
//           action="list"
//         >
//           <Menu.Item
//             key={route}
//             style={{
//               fontWeight: isSelected ? "bold" : "normal",
//             }}
//             icon={icon ?? (isRoute && <Icons.UnorderedListOutlined />)}
//           >
//             <Link to={route}>{label}</Link>
//             {!collapsed && isSelected && (
//               <div className="ant-menu-tree-arrow" />
//             )}
//           </Menu.Item>
//         </CanAccess>
//       );
//     });
//   };
//
//   const isExistAuthentication = useIsExistAuthentication();
//   const { mutate: mutateLogout } = useLogout();
//   const logout = isExistAuthentication ? (
//     <Menu.Item
//       key="logout"
//       onClick={() => mutateLogout()}
//       icon={<Icons.LogoutOutlined />}
//     >
//       ログアウト
//     </Menu.Item>
//   ) : null;
//
//   return (
//     <AntdLayout.Sider
//       collapsible
//       collapsedWidth={isMobile ? 0 : 80}
//       collapsed={collapsed}
//       breakpoint="lg"
//       onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
//       style={isMobile ? antLayoutSiderMobile : antLayoutSider}
//     >
//       <Title collapsed={collapsed} />
//       <Menu
//         selectedKeys={[selectedKey]}
//         mode="inline"
//         onClick={() => {
//           if (!breakpoint.lg) {
//             setCollapsed(true);
//           }
//         }}
//       >
//         <Menu.Item key={"/"} icon={<Icons.AppstoreAddOutlined />}>
//           <AppSelect />
//         </Menu.Item>
//         {renderTreeView(menuItems, selectedKey)}
//       </Menu>
//     </AntdLayout.Sider>
//   );
// };
