import { useSelect } from '@pankod/refine-antd';
import { Db } from '../interfaces';

export const useSelectCourses = (appId: string, props: { defaultValue?: string } = {}) => {
  return useSelect<Db['Course']>({
    resource: 'courses',
    filters: [{
      field: 'app_id',
      operator: 'eq',
      value: appId,
    }]
  });
}
