import React, { useState } from 'react';
import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Alert,
  Button,
  Edit,
  Form,
  Input,
  InputNumber,
  ListButton,
  RcFile,
  RefreshButton,
  Select,
  Upload,
  useForm,
} from '@pankod/refine-antd';

import { Db } from 'interfaces';
import { supabaseClient, normalizeFile } from 'utility';
import { TextEditor } from '../../components/textEditor';
import { User } from '@supabase/supabase-js';
import dayjs from 'dayjs';
import authProvider from '../../authProvider';
import { useAppContext } from '../../context';
import { nanoid } from 'nanoid';
import { useSelectCourses } from '../../hooks/useSelectCourses';

const getUserIdentity = authProvider.getUserIdentity ?? (() => null);

export const ChapterEdit: React.FC<IResourceComponentsProps> = () => {
  const { appId } = useAppContext();
  const [ isDeprecated, setIsDeprecated ] = useState(false);
  const { formProps, saveButtonProps, queryResult } = useForm<Db['Chapter']>({
    liveMode: 'manual',
    onLiveEvent: () => {
      setIsDeprecated(true);
    },
  });

  const postData = queryResult?.data?.data;
  const { selectProps: categorySelectProps } = useSelectCourses(appId, { defaultValue: postData?.course_id || '' });

  const handleRefresh = () => {
    queryResult?.refetch();
    setIsDeprecated(false);
  };

  return (
    <Edit
      saveButtonProps={ saveButtonProps }
      pageHeaderProps={ {
        extra: (
          <>
            <ListButton/>
            <RefreshButton onClick={ handleRefresh }/>
          </>
        ),
      } }
    >
      { isDeprecated && (
        <Alert
          message="This post is changed. Reload to see it's latest version."
          type="warning"
          style={ {
            marginBottom: 20,
          } }
          action={
            <Button
              onClick={ handleRefresh }
              size="small"
              type="ghost"
            >
              Refresh
            </Button>
          }
        />
      ) }

      <Form { ...formProps } layout="vertical">
        <Form.Item
          label="コース"
          name="course_id"
          rules={ [
            {
              required: true,
            },
          ] }
        >
          <Select { ...categorySelectProps } />
        </Form.Item>
        <Form.Item label="チャプター番号" name="order">
          <InputNumber/>
        </Form.Item>
        <Form.Item
          label="パーマリンク"
          name="permalink"
          initialValue={nanoid(10)}
          rules={ [
            {
              required: true,
            },
          ] }
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="タイトル"
          name="title"
          rules={ [
            {
              required: true,
            },
          ] }
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="要約"
          name="summary"
          rules={ [
            {
              required: false,
            },
          ] }
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="本文"
          name="contents"
          rules={ [
            {
              required: true,
            },
          ] }
        >
          {/* @ts-ignore */ }
          <TextEditor/>
        </Form.Item>
        <Form.Item label="クリア条件" name="accomplishment_type">
          <Select
            options={ [
              { label: 'コンテンツを読んだ', value: 'read' },
              { label: 'クイズのスコアを上回った', value: 'score' },
              { label: '動画を見た', value: 'play' },
            ] }
          ></Select>
        </Form.Item>
        <Form.Item label="クイズカテゴリー" name="quiz_category">
          <Input placeholder="出題するクイズのカテゴリーを指定 例) 中学校/数学/因数分解"/>
        </Form.Item>
        <Form.Item label="合格点" name="accomplishment_score_border">
          <Input placeholder={ 'クリア条件がクイズの場合の点数を半角数字で入力' }/>
        </Form.Item>
        <Form.Item label="動画">
          <Form.Item
            name="video"
            valuePropName="fileList"
            normalize={ normalizeFile }
            noStyle
          >
            <Upload.Dragger
              name="file"
              listType="picture"
              customRequest={ async ({ file, onError, onSuccess }) => {
                try {
                  const rcFile = file as RcFile;
                  const user = (await getUserIdentity()) as (User | null);
                  const fileName = `${appId}_${ user?.id }_${ dayjs().format('YYYY-MM-DD_HH-mm-ss-SSS') }`;
                  console.log(fileName, rcFile);
                  await supabaseClient.storage
                    .from('story')
                    .upload(`public/${ fileName }`, file, {
                      cacheControl: '3600',
                      upsert: true,
                    });

                  const { data } = supabaseClient.storage
                    .from('story')
                    .getPublicUrl(`public/${ fileName }`);

                  const xhr = new XMLHttpRequest();
                  onSuccess && onSuccess({ url: data?.publicURL }, xhr);
                } catch (error) {
                  onError && onError(new Error('Upload Error'));
                }
              } }
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  );
};
