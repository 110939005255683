import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  getDefaultSortOrder,
  FilterDropdown,
  Select,
  Button,
} from '@pankod/refine-antd';
import { LinkOutlined } from '@ant-design/icons';

import { Db } from 'interfaces';
import { useAppContext } from '../../context';
import { useSelectCourses } from '../../hooks/useSelectCourses';

export const ChapterList: React.FC<IResourceComponentsProps> = () => {
  const { appId } = useAppContext();
  const { tableProps, sorter } = useTable<Db['Chapter']>({
    permanentFilter: [
      {
        field: 'app_id',
        operator: 'eq',
        value: appId,
      }
    ],
    initialSorter: [
      {
        field: 'id',
        order: 'asc',
      },
    ],
    metaData: {
      select: '*, courses(title)',
    },
  });

  const { selectProps } = useSelectCourses(appId);

  return (
    <List>
      <Table { ...tableProps } rowKey="id">
        <Table.Column
          key="order"
          dataIndex="order"
          title="番号"
          sorter
          width={ '6em' }
        />
        <Table.Column
          key="title"
          dataIndex="title"
          title="タイトル"
          sorter
        />
        <Table.Column
          key="course_id"
          dataIndex={ [ 'courses', 'title' ] }
          title="Course"
          defaultSortOrder={ getDefaultSortOrder(
            'courses.title',
            sorter,
          ) }
          filterDropdown={ (props) => (
            <FilterDropdown { ...props }>
              <Select
                style={ { minWidth: 200 } }
                mode="multiple"
                placeholder="Select Course"
                { ...selectProps }
              />
            </FilterDropdown>
          ) }
          width={ '20em' }
        />
        <Table.Column
          key="permalink"
          dataIndex="permalink"
          title="パーマリンク"
          sorter
        />
        <Table.Column<Db['Chapter']>
          title="Actions"
          dataIndex="actions"
          render={ (_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={ record.id }
              />
              <Button href={ `https://hayaben.jp/genre/${ appId }/chapter/${ record.permalink }` }
                      target="_blank"
                      size="small"
                      icon={ <LinkOutlined/> }/>
            </Space>
          ) }
          width={ '5em' }
        />
      </Table>
    </List>
  );
};
