import { Select, useSelect } from '@pankod/refine-antd';
import { useAppContext } from '../../context';
import { Db } from '../../interfaces';

export const AppSelect: React.FC = () => {
  const { setAppId, appId, organizationId, role } = useAppContext();
  console.log(appId, organizationId, role)
  const { selectProps } = useSelect<Db['App']>({
    resource: 'apps',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 20,
    filters: role !== 'SUPER_ADMIN' ? [ {
      field: 'organization_id',
      operator: 'eq',
      value: organizationId,
    } ] : undefined,
    onSearch: (value) => ([
      {
        field: 'name',
        operator: 'contains',
        value,
      },
    ]),
  })

  const handleChange = (value: string) => {
    setAppId(value);
  }

  return (
    <Select
      value={ appId }
      style={ { width: 130 } }
      onChange={ handleChange }
      showSearch
      filterOption={ false }
      optionFilterProp="name"
      placeholder="アプリを選択"
      onSearch={ selectProps.onSearch }
      options={ selectProps.options }
    />
  )
}
