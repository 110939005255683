import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Create,
  Form,
  Input,
  useForm,
} from '@pankod/refine-antd';

import { Db } from 'interfaces';
import { TextEditor } from '../../components/textEditor';
import { useAppContext } from '../../context';
import { nanoid } from 'nanoid';

export const CourseCreate: React.FC<IResourceComponentsProps> = () => {
  const { appId } = useAppContext();
  const { formProps, saveButtonProps } = useForm<Db['Course']>();

  return (
    <Create saveButtonProps={ saveButtonProps }>
      <Form { ...formProps } layout="vertical">
        <Form.Item
          name="app_id"
          initialValue={ appId }
          hidden
        />

        <Form.Item
          label="パーマリンク"
          name="permalink"
          initialValue={nanoid(5)}
          rules={ [
            {
              required: true,
            },
          ] }
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="タイトル"
          name="title"
          rules={ [
            {
              required: true,
            },
          ] }
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="本文"
          name="description"
          rules={ [
            {
              required: false,
            },
          ] }
        >
          {/* @ts-ignore */ }
          <TextEditor/>
        </Form.Item>
      </Form>
    </Create>
  );
};
