import { useSelect } from '@pankod/refine-antd';
import { Db } from '../interfaces';

export const useSelectChapters = (appId: string, props: { defaultValue?: string } = {}) => {
  return useSelect<Db['Chapter']>({
    resource: 'chapters',
    filters: [{
      field: 'app_id',
      operator: 'eq',
      value: appId,
    }]
  });
}
