import React, { useState } from 'react';
import { IResourceComponentsProps } from '@pankod/refine-core';

import {
  Alert,
  Button,
  Edit,
  Form,
  Input,
  ListButton,
  RefreshButton,
  useForm,
} from '@pankod/refine-antd';

import { Db } from 'interfaces';
import { TextEditor } from '../../components/textEditor';

export const CourseEdit: React.FC<IResourceComponentsProps> = () => {
  const [ isDeprecated, setIsDeprecated ] = useState(false);
  const { formProps, saveButtonProps, queryResult } = useForm<Db['Course']>({
    liveMode: 'manual',
    onLiveEvent: () => {
      setIsDeprecated(true);
    },
  });

  const handleRefresh = () => {
    queryResult?.refetch();
    setIsDeprecated(false);
  };

  return (
    <Edit
      saveButtonProps={ saveButtonProps }
      pageHeaderProps={ {
        extra: (
          <>
            <ListButton/>
            <RefreshButton onClick={ handleRefresh }/>
          </>
        ),
      } }
    >
      { isDeprecated && (
        <Alert
          message="This post is changed. Reload to see it's latest version."
          type="warning"
          style={ {
            marginBottom: 20,
          } }
          action={
            <Button
              onClick={ handleRefresh }
              size="small"
              type="ghost"
            >
              Refresh
            </Button>
          }
        />
      ) }

      <Form { ...formProps } layout="vertical">
        <Form.Item
          label="パーマリンク"
          name="permalink"
          rules={ [
            {
              required: true,
            },
          ] }
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={ [
            {
              required: true,
            },
          ] }
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={ [
            {
              required: false,
            },
          ] }
        >
          {/* @ts-ignore */ }
          <TextEditor/>
        </Form.Item>
      </Form>
    </Edit>
  );
};
